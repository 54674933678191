import React,{useState,useEffect} from 'react'
import Booking from '../components/Booking'
import {fetchPackageData} from '../lib/packages';
import { useNavigate } from 'react-router-dom';





export default function Home() {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    const loadPackages = async () => {
      const data = await fetchPackageData('safari-days');
      setPackages(data);
    };
    
    loadPackages();
  }, []);
  const firstThreePackages = packages.slice(0, 3)

  const handleBookNow = (pkg) => {
    const bookingId = pkg.id;
    window.location.href = `/packages/${bookingId}`;
  };

  return (
    <div>
      <div className="container-fluid bg-primary py-5 mb-5 hero-header">
        <div className="container py-5">
        <div className="row justify-content-center py-5">
  <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
    <h1 className="display-2 text-white mb-3 animated slideInDown font-roboto">
      Your Perfect Vacation Awaits
    </h1>
    <p className="fs-10 text-white mb-4 animated slideInDown">
      Explore destinations, book unforgettable experiences, and travel hassle-free with us.
    </p>
    <a
      className="btn btn-light text-primary border-primary rounded-pill mt-3 mt-md-5 shadow-sm"
      href="/packages"
      style={{
        width: "75%", // Default width for small screens
        maxWidth: "300px", // Restrict width on medium and larger screens
        padding: "0.5rem 1rem", // Smaller padding for medium and larger screens
        fontSize: "0.9rem", // Smaller font size for a more compact look
      }}
    >
      Explore Packages
    </a>
  </div>
</div>

        </div>
      </div>
            {/* About Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div
            className="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ minHeight: 400 }}
          >
            <div className="position-relative h-100">
              <img
                className="img-fluid position-absolute w-100 h-100"
                src="assets/img/about.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h6 className="section-title bg-white text-start text-primary pe-3">
              About Us
            </h6>
            <h1 className="mb-4">
              Welcome to <span className="text-primary">Safari Days</span>
            </h1>
            <p className="mb-4">
            At Safari Days, we are passionate about creating unforgettable travel experiences. Whether you're seeking adventure, relaxation, or a mix of both, we ensure your journey is seamless and extraordinary.
            </p>
            <p className="mb-4">
            With years of experience, a team of travel experts, and access to premium services, we bring your dream vacations to life. Explore the world's most stunning destinations with the confidence that every detail is taken care of.
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Inbound and Outbound Tour Packages
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Hotel Booking
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />5 Star
                  Ticketing and Visa
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Bike and Car Rent Services
                  </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  House Boat and Cruise Services
                  </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2" />
                  Medical Services
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* About End */}
    {/* Service Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Services
          </h6>
          <h1 className="mb-5">Our Services</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-globe text-primary mb-4" />
                <h5>Inbound and Outbound Tour Packages</h5>
                <p>
                Explore the world with custom tour packages designed for every traveler.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-hotel text-primary mb-4" />
                <h5>Hotel Reservation</h5>
                <p>
                Easily find your perfect stay with options from budget hotels to luxury resorts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-user text-primary mb-4" />
                <h5>Travel Guides</h5>
                <p>
                Get expert travel guides to make your trips easy and unforgettable.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-motorcycle text-primary mb-4" />
                <h5>Bike and Car Rent Services</h5>
                <p>
                Discover destinations your way with our flexible bike and car rental services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-car text-primary mb-4" />
                <h5>Cab Services</h5>
                <p>
                Enjoy safe and reliable cab services for all your travel needs, anytime, anywhere.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-plane text-primary mb-4" />
                <h5>Ticketing and Visa </h5>
                <p>
                Leave your travel planning to us with seamless ticketing, visas, and insurance for a stress-free journey.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-ship text-primary mb-4" />
                <h5>House Boat and Cruise Services
                </h5>
                <p>
                Discover stunning waterways with luxurious houseboat stays and thrilling cruise adventures.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-cog text-primary mb-4" />
                <h5>Helicopter Services</h5>
                <p>
                Enjoy fast, scenic, and convenient travel with our helicopter services for tours, charters, and transfers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Service End */}
    {/* Destination Start */}
    <div className="container-xxl py-5 destination">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Destination
          </h6>
          <h1 className="mb-5">Popular Destination</h1>
        </div>
        <div className="row g-3">
          <div className="col-lg-7 col-md-6">
            <div className="row g-3">
              <div
                className="col-lg-12 col-md-12 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <a
                  className="position-relative d-block overflow-hidden"
                  href=""
                >
                  <img
                    className="img-fluid"
                    src="assets/img/destination-1.jpg"
                    alt=""
                  />
                  <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                  </div>
                  <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                    Japan
                  </div>
                </a>
              </div>
              <div
                className="col-lg-6 col-md-12 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <a
                  className="position-relative d-block overflow-hidden"
                  href=""
                >
                  <img
                    className="img-fluid"
                    src="assets/img/destination-2.jpg"
                    alt=""
                  />
                  <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                  </div>
                  <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                    China
                  </div>
                </a>
              </div>
              <div
                className="col-lg-6 col-md-12 wow zoomIn"
                data-wow-delay="0.5s"
              >
                <a
                  className="position-relative d-block overflow-hidden"
                  href=""
                >
                  <img
                    className="img-fluid"
                    src="assets/img/destination-3.jpg"
                    alt=""
                  />
                  <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                  </div>
                  <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                  Malaysia
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-6 wow zoomIn"
            data-wow-delay="0.7s"
            style={{ minHeight: 350 }}
          >
            <a
              className="position-relative d-block h-100 overflow-hidden"
              href=""
            >
              <img
                className="img-fluid position-absolute w-100 h-100"
                src="assets/img/destination-4.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
              <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
              </div>
              <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                Australia
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* Destination Start */}
    {/* Package Start */}
    <div className="container-xxl py-5" onClick={() => navigate('/packages')}>
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Packages
          </h6>
          <h1 className="mb-5">Awesome Packages</h1>
        </div>
        <div className="row g-4 justify-content-center">
          {firstThreePackages.map((pkg) => (
            <div
              key={pkg.name}
              className="col-lg-4 col-md-6 p-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item shadow-lg rounded-3" style={{ 
                height: '500px',
                transition: 'transform 0.3s ease-in-out',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column'
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
              onMouseOut={(e) => e.currentTarget.style.transform = 'translateY(0)'}
              >
                <div className="overflow-hidden position-relative" style={{ height: '250px' }}>
                  <img 
                    className="img-fluid w-100 h-100" 
                    src={pkg.imageUrl} 
                    alt={pkg.name} 
                    style={{ 
                      objectFit: 'cover',
                      borderTopLeftRadius: '0.5rem',
                      borderTopRightRadius: '0.5rem'
                    }} 
                  />
                  <div className="position-absolute bottom-0 start-0 w-100 p-3" 
                       style={{ 
                         background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                         color: 'white'
                       }}>
                  </div>
                </div>
                <div className="d-flex border-bottom bg-light">
                  <small className="flex-fill text-center border-end py-3 text-truncate">
                    <i className="fa fa-map-marker-alt text-primary me-2" />
                    ₹{pkg.price} / Person
                  </small>
                  <small className="flex-fill text-center border-end py-3">
                    <i className="fa fa-calendar-alt text-primary me-2" />
                    {pkg.days} days
                  </small>
                  <small className="flex-fill text-center py-3">
                    <i className="fa fa-user text-primary me-2" />
                    {pkg.maxPeople} Person
                  </small>
                </div>
                <div className="d-flex flex-column justify-content-between flex-grow-1 p-4">
                  <div>
                    <h4 className="text-primary mb-3">{pkg.name}</h4>
                    <p className="text-muted mb-0" style={{ 
                      height: '24px',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis'
                    }}>{pkg.description}</p>
                  </div>
                  <div className="d-flex justify-content-center gap-2 mt-3">
                    <a
                      href="https://wa.me/+919497192849"
                      target='_blank'
                      className="btn btn-outline-primary px-4 py-2 rounded-pill"
                    >
                      Contact us
                    </a>
                    <button
                      className="btn btn-primary px-4 py-2 rounded-pill"
                      onClick={() => handleBookNow(pkg)}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    {/* Package End */}
<Booking/>
    {/* Team Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Travel Guide
          </h6>
          <h1 className="mb-5">Meet Our Team</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item">
              <div className="overflow-hidden">
                <img className="img-fluid" src="assets/img/team-1.jpg" alt="" />
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">KM Shamsudheen</h5>
                <small>Managing Director</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="team-item">
              <div className="overflow-hidden">
                <img className="img-fluid" src="assets/img/team-2.jpg" alt="" />
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">Sheeba Jacob</h5>
                <small>Business Development Manager</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item">
              <div className="overflow-hidden">
                <img className="img-fluid" src="assets/img/team-3.jpg" alt="" />
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">Parvathi Prasad</h5>
                <small>Manager - Outbound</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Team End */}
    {/* Testimonial Start */}
    <div className="container-xxl py-5 wow fadeInUp overflow-hidden" data-wow-delay="0.1s">
  <div className="container">
    <div className="text-center">
      <h6 className="section-title bg-white text-center text-primary px-3">
        Testimonial
      </h6>
      <h1 className="mb-5">Our Clients Say!!!</h1>
    </div>
    <div className="owl-carousel testimonial-carousel position-relative">
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Harshitha S</h5>
        <p>Bangalore, India</p>
        <p className="mb-0">
          "Loved every moment with Safari Days – the guides were amazing, and the wildlife was breathtaking!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Mr. Patel</h5>
        <p>Ahmedabad, India</p>
        <p className="mt-2 mb-0">
          "A truly unforgettable safari experience – everything was perfectly planned!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Deepika Singh</h5>
        <p>Delhi, India</p>
        <p className="mt-2 mb-0">
          "Safari Days made my dream trip come true – magical and well-organized!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Dr. Kapil</h5>
        <p>Pune, India</p>
        <p className="mt-2 mb-0">
          "The best safari experience I've had – safe, exciting, and beautiful."
        </p>
      </div>
    </div>
  </div>
</div>

    {/* Testimonial End */}  
    </div>
  )
}
