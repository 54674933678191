export const fetchPackageData = async (website) => {
  try {
    const response = await fetch(`https://api.safaridays.com/api/${website}/get`);
    if (!response.ok) {
      throw new Error('Failed to fetch package data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching package data:', error);
    return [];
  }
};