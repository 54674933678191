import React from 'react'

export default function Footer() {
  return (
    <div>
           {/* Footer Start */}
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6 col-md-6">
            <h4 className="text-white mb-3">Safari Days</h4>
            <a className="btn btn-link" href="/about">
              About Us
            </a>
            <a className="btn btn-link" href="/contact">
              Contact Us
            </a>
          </div>
          <div className="col-lg-6 col-md-6">
            <h4 className="text-white mb-3">Contact</h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3" />
              T.V Complex, Calicut road Changuvetti Kottakkal,

              Malappuram Dt. Kerala 676501
            </p>
            <p className="mb-4">
              <i className="fa fa-map-marker-alt me-3" />
              Courtyard Hotels Marriot Business Centre VIP Road

              Opposite Kochi International Airport,

              Nedumbassery, Vappalassery, Kochi, 683572
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3" />
              +919446200006
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3" />
              mail@ksa.co.in
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/ksatours/" target='_blank'>
                <i className="fab fa-instagram" />
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/ksatoursandtravelspvtltd/" target='_blank'>
                <i className="fab fa-facebook-f" />
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/c/SAFARIDAYS/videos" target='_blank'>
                <i className="fab fa-youtube" />
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/ksa-tours-and-travels-pvt-ltd/" target='_blank'>
                <i className="fab fa-linkedin-in" />
              </a>
              <a className="btn btn-outline-light btn-social" href="https://wa.me/+919497192849" target='_blank'>
                <i className="fab fa-whatsapp" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a className="border-bottom" href="#">
                Safari Days
              </a>
              , All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer End */}
    </div>
  )
}
