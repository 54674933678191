import React from 'react'

function Services() {
  return (
    <div>
          <div className="container-fluid bg-primary py-5 mb-5 hero-header">
    <div className="container py-5">
      <div className="row justify-content-center py-5">
        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
          <h1 className="display-3 text-white animated slideInDown">
            Services
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
      {/* Service Start */}
      <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Services
          </h6>
          <h1 className="mb-5">Our Services</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-globe text-primary mb-4" />
                <h5>Inbound and Outbound Tour Packages</h5>
                <p>
                Explore the world with custom tour packages designed for every traveler.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-hotel text-primary mb-4" />
                <h5>Hotel Reservation</h5>
                <p>
                Easily find your perfect stay with options from budget hotels to luxury resorts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-user text-primary mb-4" />
                <h5>Travel Guides</h5>
                <p>
                Get expert travel guides to make your trips easy and unforgettable.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-motorcycle text-primary mb-4" />
                <h5>Bike and Car Rent Services</h5>
                <p>
                Discover destinations your way with our flexible bike and car rental services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-car text-primary mb-4" />
                <h5>Cab Services</h5>
                <p>
                Enjoy safe and reliable cab services for all your travel needs, anytime, anywhere.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-plane text-primary mb-4" />
                <h5>Ticketing and Visa </h5>
                <p>
                Leave your travel planning to us with seamless ticketing, visas, and insurance for a stress-free journey.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-ship text-primary mb-4" />
                <h5>House Boat and Cruise Services
                </h5>
                <p>
                Discover stunning waterways with luxurious houseboat stays and thrilling cruise adventures.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item rounded pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-cog text-primary mb-4" />
                <h5>Helicopter Services</h5>
                <p>
                Enjoy fast, scenic, and convenient travel with our helicopter services for tours, charters, and transfers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Service End */}
    {/* Testimonial Start */}
    <div className="container-xxl py-5 wow fadeInUp overflow-hidden" data-wow-delay="0.1s">
  <div className="container">
    <div className="text-center">
      <h6 className="section-title bg-white text-center text-primary px-3">
        Testimonial
      </h6>
      <h1 className="mb-5">Our Clients Say!!!</h1>
    </div>
    <div className="owl-carousel testimonial-carousel position-relative">
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Harshitha S</h5>
        <p>Bangalore, India</p>
        <p className="mb-0">
          "Loved every moment with Safari Days – the guides were amazing, and the wildlife was breathtaking!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Mr. Patel</h5>
        <p>Ahmedabad, India</p>
        <p className="mt-2 mb-0">
          "A truly unforgettable safari experience – everything was perfectly planned!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Deepika Singh</h5>
        <p>Delhi, India</p>
        <p className="mt-2 mb-0">
          "Safari Days made my dream trip come true – magical and well-organized!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Dr. Kapil</h5>
        <p>Pune, India</p>
        <p className="mt-2 mb-0">
          "The best safari experience I’ve had – safe, exciting, and beautiful."
        </p>
      </div>
    </div>
  </div>
</div>
    {/* Testimonial End */}
    </div>
  )
}

export default Services