import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function PackageDetails() {
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const cachedData = localStorage.getItem(`package_${id}`);
        
        if (cachedData) {
          setPackageData(JSON.parse(cachedData));
          setLoading(false);
          return;
        }

        const response = await axios.get(`https://api.safaridays.com/api/safari-days/get/${id}`);
        setPackageData(response.data);
        localStorage.setItem(`package_${id}`, JSON.stringify(response.data));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching package:', error);
        setLoading(false);
      }
    };
    fetchPackage();
  }, [id]);

  if (loading) return <div className="d-flex justify-content-center align-items-center min-vh-100">Loading...</div>;
  if (!packageData) return <div className="d-flex justify-content-center align-items-center min-vh-100">Package not found</div>;

  return (
    <div className="container-fluid px-4 py-5 bg-light" 
         style={{ 
           borderRadius: '24px',
           marginTop: '80px'
         }}>
      {/* Hero Section */}
      <div className="position-relative mb-5 overflow-hidden shadow-lg" 
           style={{ 
             height: '600px', 
             borderRadius: '32px',
             transition: 'transform 0.3s ease',
           }}>
        <img 
          src={packageData?.imageUrl} 
          alt={packageData?.name} 
          className="w-100 h-100"
          style={{ 
            objectFit: 'cover',
            borderRadius: '32px'
          }}
        />
        <div className="position-absolute bottom-0 start-0 w-100 text-white" 
             style={{ 
               background: 'linear-gradient(to top, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.6) 50%, transparent 100%)',
               padding: '8rem 3rem 3rem',
               borderBottomLeftRadius: '32px',
               borderBottomRightRadius: '32px'
             }}>
          <h1 className="display-4 text-white fw-bold mb-3">{packageData?.name}</h1>
          <p className="lead mb-0" style={{ opacity: '0.95' }}>
            <i className="bi bi-geo-alt-fill me-2"></i>
            {packageData?.location[0]}
          </p>
        </div>
      </div>

      <div className="row g-4">
        {/* Main Content */}
        <div className="col-lg-8">
          <div className="card border-0 shadow-sm mb-4" 
               style={{ 
                 borderRadius: '24px',
                 transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                 ':hover': { transform: 'translateY(-5px)', boxShadow: '0 1rem 3rem rgba(0,0,0,.175)' }
               }}>
            <div className="card-body p-4 p-lg-5">
              <h2 className="card-title h3 mb-4 fw-bold" style={{ color: '#2563eb' }}>About This Package</h2>
              <p className="card-text lead" style={{ color: '#4b5563' }}>{packageData?.description}</p>
            </div>
          </div>

          {/* Itinerary */}
          <div className="card border-0 shadow-sm" style={{ borderRadius: '24px' }}>
            <div className="card-body p-4 p-lg-5">
              <h2 className="card-title h3 text-center mb-5 fw-bold" style={{ color: '#2563eb' }}>Your Journey Day by Day</h2>
              <div className="row g-4">
                {packageData?.itinerary?.map((day, index) => (
                  <div key={index} className="col-md-6">
                    <div className="card h-100 border-0 shadow-sm" 
                         style={{ 
                           borderRadius: '20px',
                           transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                           ':hover': { transform: 'translateY(-5px)', boxShadow: '0 1rem 3rem rgba(0,0,0,.175)' }
                         }}>
                      <div className="card-body p-4">
                        <h3 className="h5 mb-4 fw-bold" style={{ color: '#2563eb' }}>Day {day?.day}</h3>
                        {day?.imageUrl && (
                          <img 
                            src={day.imageUrl} 
                            alt={`Day ${day?.day}`} 
                            className="img-fluid mb-4 shadow-sm"
                            style={{ 
                              height: '200px', 
                              width: '100%', 
                              objectFit: 'cover',
                              borderRadius: '16px'
                            }}
                          />
                        )}
                        <ul className="list-unstyled">
                          {day?.points?.map((point, idx) => (
                            <li key={idx} className="mb-3 d-flex align-items-start">
                              <i className="bi bi-check2-circle text-primary me-2 mt-1"></i>
                              <span className="text-muted">{point}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="col-lg-4">
          <div className="card border-0 shadow-lg sticky-top" 
               style={{ 
                 borderRadius: '24px',
                 top: '2rem',
                 background: 'linear-gradient(135deg, #2563eb 0%, #3b82f6 100%)'
               }}>
            <div className="card-body p-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="display-6 text-white fw-bold mb-0">₹{packageData?.price}</h3>
                <span className="badge bg-white text-primary px-3 py-2">per person</span>
              </div>
              <div className="mb-4 bg-white bg-opacity-10 p-3 rounded-3">
                <div className="d-flex justify-content-between mb-3">
                  <span className="fw-light">Duration</span>
                  <span className="fw-bold">{packageData?.days} Days, {packageData?.nights} Nights</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="fw-light">Max People</span>
                  <span className="fw-bold">{packageData?.maxPeople}</span>
                </div>
              </div>
              <a 
                href={`https://wa.me/+919495716208?text=Hi, I'm interested in the ${packageData?.name} package.`} 
                target="_blank" 
                rel="noopener noreferrer"
                className="btn btn-lg btn-light w-100 fw-bold shadow-sm hover-scale"
              >
                <i className="bi bi-whatsapp me-2"></i>
                Enquire on WhatsApp
              </a>
            </div>
          </div>

          {/* Inclusions */}
          <div className="card border-0 shadow-sm" style={{ borderRadius: '24px' }}>
            <div className="card-body p-4">
              <h3 className="h5 fw-bold text-success mb-4">
                <i className="bi bi-check-circle-fill me-2"></i>
                What's Included
              </h3>
              <ul className="list-unstyled">
                {packageData?.inclusions?.map((item, index) => (
                  <li key={index} className="mb-3 d-flex align-items-start">
                    <i className="bi bi-check2 text-success me-2 mt-1"></i>
                    <span className="text-muted">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Exclusions */}
          <div className="card border-0 shadow-sm" style={{ borderRadius: '24px' }}>
            <div className="card-body p-4">
              <h3 className="h5 fw-bold text-danger mb-4">
                <i className="bi bi-x-circle-fill me-2"></i>
                What's Not Included
              </h3>
              <ul className="list-unstyled">
                {packageData?.exclusions?.map((item, index) => (
                  <li key={index} className="mb-3 d-flex align-items-start">
                    <i className="bi bi-x text-danger me-2 mt-1"></i>
                    <span className="text-muted">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageDetails;
