import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import WelcomeScreen from "./Splash";

function Header() {
  const [showSplash, setShowSplash] = useState(true);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    if (isHomePage) {
      const timer = setTimeout(() => {
        setShowSplash(false);
      }, 2100);

      return () => clearTimeout(timer);
    } else {
      setShowSplash(false);
    }
  }, [isHomePage]);

  return (
    <div>
      {showSplash && isHomePage ? (
        <WelcomeScreen />
      ) : (
        <>
          {/* Topbar Start */}
          <div className="container-fluid bg-dark px-5 d-none d-lg-block">
            <div className="row gx-0">
              <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                <div
                  className="d-inline-flex align-items-center"
                  style={{ height: 45 }}
                >
                  <small className="me-3 text-light">
                    <i className="fa fa-map-marker-alt me-2" />
                    Courtyard Marriott, VIP Road, Kochi, 683572, India
                  </small>
                  <small className="me-3 text-light">
                    <i className="fa fa-phone-alt me-2" />
                    +919446200006
                  </small>
                  <small className="text-light">
                    <i className="fa fa-envelope-open me-2" />
                    mail@ksa.co.in
                  </small>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <div
                  className="d-inline-flex align-items-center"
                  style={{ height: 45 }}
                >
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href="https://wa.me/919497192849"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-whatsapp fw-normal" />
                  </a>
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href="https://www.facebook.com/ksatoursandtravelspvtltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f fw-normal" />
                  </a>
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href="https://www.linkedin.com/company/ksa-tours-and-travels-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in fw-normal" />
                  </a>
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                    href="https://www.instagram.com/ksatours/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram fw-normal" />
                  </a>
                  <a
                    className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                    href="https://www.youtube.com/c/SAFARIDAYS/videos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-youtube fw-normal" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Topbar End */}

          {/* Navbar & Hero Start */}
          <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{ minHeight: "120px" }}>
              <Link to="/" className="navbar-brand p-0">
                <img
                  src="/assets/img/logo.png"
                  alt="Logo"
                  style={{ 
                    width: "100px", 
                    height: "100px",
                    minWidth: "100px",
                    minHeight: "100px",
                    objectFit: "contain"
                  }}
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="fa fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                  <Link to="/" className="nav-item nav-link">
                    Home
                  </Link>
                  <Link to="/about" className="nav-item nav-link">
                    About
                  </Link>
                  <Link to="/services" className="nav-item nav-link">
                    Services
                  </Link>
                  <Link to="/packages" className="nav-item nav-link">
                    Packages
                  </Link>
                  <Link to="/contact" className="nav-item nav-link">
                    Contact
                  </Link>
                </div>
              </div>
            </nav>
          </div>
          {/* Navbar & Hero End */}
        </>
      )}
    </div>
  );
}

export default Header;
