import React from 'react'

function Testimonial() {
  return (
    <div>
          <div className="container-fluid bg-primary py-5 mb-5 hero-header">
    <div className="container py-5">
      <div className="row justify-content-center py-5">
        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
          <h1 className="display-3 text-white animated slideInDown">
            Testimonial
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Testimonial
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
      {/* Testimonial Start */}
      <div className="container-xxl py-5 wow fadeInUp overflow-hidden" data-wow-delay="0.1s">
  <div className="container">
    <div className="text-center">
      <h6 className="section-title bg-white text-center text-primary px-3">
        Testimonial
      </h6>
      <h1 className="mb-5">Our Clients Say!!!</h1>
    </div>
    <div className="owl-carousel testimonial-carousel position-relative">
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Harshitha S</h5>
        <p>Bangalore, India</p>
        <p className="mb-0">
          "Loved every moment with Safari Days – the guides were amazing, and the wildlife was breathtaking!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Mr. Patel</h5>
        <p>Ahmedabad, India</p>
        <p className="mt-2 mb-0">
          "A truly unforgettable safari experience – everything was perfectly planned!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Deepika Singh</h5>
        <p>Delhi, India</p>
        <p className="mt-2 mb-0">
          "Safari Days made my dream trip come true – magical and well-organized!"
        </p>
      </div>
      <div className="testimonial-item bg-white text-center border p-4">
        <h5 className="mb-0">Dr. Kapil</h5>
        <p>Pune, India</p>
        <p className="mt-2 mb-0">
          "The best safari experience I’ve had – safe, exciting, and beautiful."
        </p>
      </div>
    </div>
  </div>
</div>

    {/* Testimonial End */}
    </div>
  )
}

export default Testimonial
