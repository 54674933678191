import React from "react";

const WelcomeScreen = () => {
  return (
    <div
      id="welcome-screen"
      className="position-fixed translate-middle hero-header w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      style={{
        zIndex: 1050,
        backgroundColor: "#097969", // Light green background
      }}
    >
      <div className="text-center">
        {/* Animated Text */}
        <div
          style={{
            fontSize: "clamp(1.5rem, 5vw, 3rem)", // Responsive font size
            fontWeight: "bold",
            color: "white", // White text
            overflow: "hidden",
            whiteSpace: "nowrap",
            borderRight: "0.15em solid white", // Border relative to text size
            width: "fit-content",
            margin: "0 auto",
            animation: "typing 2s steps(40) 1s forwards, blink 0.5s step-end infinite",
          }}
        >
          Welcome to Safari Days
        </div>
      </div>
      {/* Keyframes for animations */}
      <style>
        {`
          @keyframes typing {
            from {
              width: 0;
            }
            to {
              width: 100%;
            }
          }

          @keyframes blink {
            50% {
              border-color: transparent;
            }
          }
        `}
      </style>
    </div>
  );
};

export default WelcomeScreen;
