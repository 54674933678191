import React, {useState, useEffect} from 'react';
import Booking from '../components/Booking';
import {fetchPackageData} from '../lib/packages';

function Packages() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const loadPackages = async () => {
      const data = await fetchPackageData('safari-days');
      setPackages(data);
    };
    
    loadPackages();
  }, []);

  const handleBookNow = (pkg) => {
    const bookingId = pkg.id;
    window.location.href = `/packages/${bookingId}`;
  };

  return (
    <div>
      <div className="container-fluid bg-primary py-5 mb-5 hero-header">
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Packages
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Packages
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Package Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Packages
            </h6>
            <h1 className="mb-5">Awesome Packages</h1>
          </div>
          <div className="row g-4 justify-content-center">
          {packages.map((pkg) => (
              <div
                key={pkg.name}
                className="col-lg-4 col-md-6 p-3 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="package-item shadow-lg rounded-3" style={{ 
                  height: '500px',
                  transition: 'transform 0.3s ease-in-out',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column'
                 }}
                 onMouseOver={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
                 onMouseOut={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                >
                  <div className="overflow-hidden position-relative" style={{ height: '250px' }}>
                    <img 
                      className="img-fluid w-100 h-100" 
                      src={pkg.imageUrl} 
                      alt={pkg.name} 
                      style={{ 
                        objectFit: 'cover',
                        borderTopLeftRadius: '0.5rem',
                        borderTopRightRadius: '0.5rem'
                      }} 
                    />
                    <div className="position-absolute bottom-0 start-0 w-100 p-3" 
                         style={{ 
                           background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                           color: 'white'
                         }}>
                      {/* <h3 className="mb-0 fs-4 fw-bold text-white">₹{pkg.price} / Person</h3> */}
                    </div>
                  </div>
                  <div className="d-flex border-bottom bg-light">
                    <small className="flex-fill text-center border-end py-3 text-truncate">
                      <i className="fa fa-map-marker-alt text-primary me-2" />
                      ₹{pkg.price} / Person
                    </small>
                    <small className="flex-fill text-center border-end py-3">
                      <i className="fa fa-calendar-alt text-primary me-2" />
                      {pkg.days} days
                    </small>
                    <small className="flex-fill text-center py-3">
                      <i className="fa fa-user text-primary me-2" />
                      {pkg.maxPeople} Person
                    </small>
                  </div>
                  <div className="d-flex flex-column justify-content-between flex-grow-1 p-4">
                    <div>
                      <h4 className="text-primary mb-3">{pkg.name}</h4>
                      <p className="text-muted mb-0" style={{ 
                        height: '24px',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis'
                      }}>{pkg.description}</p>
                    </div>
                    <div className="d-flex justify-content-center gap-2 mt-3">
                      <a
                        href="https://wa.me/+919497192849"
                        target='_blank'
                        className="btn btn-outline-primary px-4 py-2 rounded-pill"
                      >
                        Contact us
                      </a>
                      <button
                        className="btn btn-primary px-4 py-2 rounded-pill"
                        onClick={() => handleBookNow(pkg)}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Package End */}
      <Booking />
    </div>
  );
}

export default Packages;
