import React, { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';

function PaymentPage() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderDetails, setOrderDetails] = useState({
    orderId: '',
    basePrice: '',
    gst: '',
    totalAmount: '',
    packageId: '',
    numberOfPeople: '',
    statusMessage: ''
  });
  const [validationError, setValidationError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      // Get order ID and error from URL parameters
      const params = new URLSearchParams(window.location.search);
      const orderIdFromUrl = params.get('order_id');
      const errorFromUrl = params.get('error');
      
      // If there's an error in the URL, show it as an alert
      if (errorFromUrl) {
        alert(decodeURIComponent(errorFromUrl));
      }

      // If there's an order ID in the URL, use it and store it
      if (orderIdFromUrl) {
        localStorage.setItem('orderId', orderIdFromUrl);
      }

      const orderId = localStorage.getItem('orderId');
      
      if (!orderId) {
        setPaymentStatus('ERROR');
        return;
      }

      try {
        const response = await fetch('https://api.safaridays.com/api/handleJuspayResponse', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({ order_id: orderId }),
        });
        const data = await response.json();
        
        if (data.error && data.message && data.message.includes('Payment validation failed')) {
          setValidationError(data.message);
          setPaymentStatus('VALIDATION_ERROR');
        } else {
          setPaymentStatus(data.status);
          setOrderDetails({
            orderId: orderId,
            basePrice: data.udf1?.split(' ')[1]/100 || '0',
            gst: data.udf2?.split(' ')[1]/100 || '0',
            totalAmount: data.amount || '0',
            packageId: data.udf6?.split(' ')[1] || '',
            numberOfPeople: data.udf3?.split(' ')[1] || '1',
            statusMessage: data.statusMessage || ''
          });
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setPaymentStatus('ERROR');
      }
    };

    checkPaymentStatus();
  }, []);

  // Updated OrderDetails component
  const OrderDetails = () => (
    <div className="border-top mt-4 pt-4">
      <div className="row g-3">
        <div className="col-12">
          <p className="text-muted mb-1 small">Order ID</p>
          <p className="fw-semibold mb-3 font-monospace">{orderDetails.orderId}</p>
        </div>
        <div className="col-6">
          <p className="text-muted mb-1 small">Base Price</p>
          <p className="mb-0">₹{orderDetails.basePrice}</p>
        </div>
        <div className="col-6 text-end">
          <p className="text-muted mb-1 small">GST (3.5%)</p>
          <p className="mb-0">₹{orderDetails.gst}</p>
        </div>
        <div className="col-6">
          <p className="text-muted mb-1 small">Number of People</p>
          <p className="mb-0">{orderDetails.numberOfPeople}</p>
        </div>
        <div className="col-6 text-end">
          <p className="text-muted mb-1 small">Total Amount</p>
          <p className="fw-semibold mb-0">₹{orderDetails.totalAmount}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center min-vh-100 bg-light p-4">
      <div className="card p-4 shadow-sm" style={{maxWidth: '500px'}}>
        {paymentStatus === 'CHARGED' && (
          <div className="text-center mt-5">
            <div className="d-flex align-items-center justify-content-center mb-4 mx-auto bg-success-subtle rounded-circle" style={{width: '4rem', height: '4rem'}}>
              <i className="bi bi-check-lg text-success fs-3"></i>
            </div>
            <h2 className="h2 mb-2 text-success">Payment Successful</h2>
            <p className="text-muted small">Thank you for your purchase. Your order has been confirmed.<br/>Our team will contact you soon.</p>
          </div>
        )}

        {paymentStatus === 'PENDING' && (
          <div className="text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-blue-50 flex items-center justify-center">
              <svg className="w-8 h-8 text-blue-600 animate-spin" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold mb-2 text-gray-900">Processing Payment</h2>
            <p className="text-gray-600 text-sm">Please wait while we confirm your payment...</p>
          </div>
        )}

        {(paymentStatus === 'AUTHENTICATION_FAILED' || paymentStatus === 'AUTHORIZATION_FAILED') && (
          <div className="text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-red-50 flex items-center justify-center">
              <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold mb-2 text-gray-900">Payment Failed</h2>
            <p className="text-gray-600 text-sm">We couldn't process your payment. Please try again.</p>
          </div>
        )}

        {paymentStatus === 'VALIDATION_ERROR' && (
          <div className="text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-red-50 flex items-center justify-center">
              <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold mb-2 text-gray-900">Payment Validation Error</h2>
            <p className="text-gray-600 text-sm">A security validation error occurred:</p>
            <p className="text-red-600 text-sm mt-2">{validationError}</p>
            <p className="text-gray-600 text-sm mt-2">Please contact support with your order ID.</p>
          </div>
        )}

        {paymentStatus === 'ERROR' && (
          <div className="text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-gray-50 flex items-center justify-center">
              <svg className="w-8 h-8 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold mb-2 text-gray-900">Something Went Wrong</h2>
            <p className="text-gray-600 text-sm">An error occurred while processing your payment.</p>
          </div>
        )}

        {/* Add OrderDetails before the return home button */}
        {paymentStatus && orderDetails.orderId && <OrderDetails />}

        <button
          onClick={() => navigate('/')}
          className="btn btn-dark d-flex align-items-center justify-content-center gap-2 w-100 mt-4"
        >
          <i className="bi bi-arrow-left"></i>
          Return to Home
        </button>
      </div>
    </div>
  );
}

export default PaymentPage;
  